import { EventServiceInstance } from '@/services/EventService'
// EventConfigDB,
import { EventConfigForm } from '@/models/EventConfig'
import { isObjectEmpty } from '@/utils/isObjectEmpty'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
import { axiosDownloadable } from '@/utils/axiosDownloadable'
import { ElementTypeKeys } from '@/enums/ElementTypeKeys'
import { isHappening } from '@/utils/dateHelpers'
import { VideoKeys } from '@/enums/VideoKeys'
import { EventNotificationsInstance } from '@/services/EventNotifications'
import { AccessControlsServiceInstance } from '@/services/AccessControlsService'
import { quickNotesServiceInstance } from '@/services/quickNotesService'
import { EventHybridServiceInstance } from '@/services/EventHybridService'
import { RegistriesCalendarsService } from '@/services/RegistriesCalendarsService'
import { CalendarRequestService } from '@/services/RegistriesCalendarsRequestService'
import { CalendarsItemsService } from '@/services/CalendarsItemsService'
import { debounce } from 'lodash'
// import { EventNavItemBD } from "@/models/EventNavItem";
export const helper = {
  IS_TOUCH_DEVICE: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
}
const currentContentLibraryElementId = (state, getters) => {
  if (state.contentItem.contentItemModal) {
    return state.contentItem.contentItemData.id
  }
  if (state.contentVideo.contentVideoModal) {
    return state.contentVideo.contentVideoData.id
  }
  if (getters.emebedContentElement) {
    return getters.emebedContentElement.id
  }
  return null
}
// state
const initState = () => {
  return {
    constants: {
      users_loading: false,
      needs_refershing: false,
      needs_hybrid_refershing: false,
      is_user_in_event: false,
      is_preview: true,
      is_desktop: true,
      room_width: 1680,
      room_height: 1050,
      window_width: 1680,
      window_height: 1050,
      constantsLoaded: false,
      // Donovann
      threads: [],
      channels: [],
      notifications: [],
      // End Donovann
      event_configs: {
        data: {
          current_config_language_key: 'es',
          es: EventConfigForm(),
          en: EventConfigForm(),
        },
      },
      nav_items: {
        data: {
          es: [],
          en: [],
        },
      },
      virtual_rooms: [],
      rep_rooms: [],
      registry: {
        forms: [],
        configs: [],
        nav_items: [],
      },
      license: {},
      event_connections: [],
      event_users_block: [],
      profiles: [],
      favorites: [],
      nav_count: 0,
      uuid_tracker: null,
      navigation_log_virtual_room_ids: [],
      version: '1.0.0',
      quick_notes: [],
    },
    flexible: {
      event_calendar_modal: false,
    },
    iframeItem: {
      iframeItemModal: false,
      iframeItemUrl: '',
      iframeItemHtml: '',
      iframeItemType: 'link',
    },
    simpleVideoItem: {
      simpleVideoItemModal: false,
      simpleVideoItemUrl: '',
      simpleVideoItemType: 'youtube',
    },
    contentItem: {
      contentItemModal: false,
      contentItemData: {},
    },
    contentVideo: {
      contentVideoModal: false,
      contentVideoData: {},
    },
    activeInmediatePopUp: {
      contentVideoModal: false,
    },
    contentWindow: {
      contentWindowModal: false,
      contentWindowData: {
        content_item_elements: [],
      },
    },
    search: {
      modal: false,
    },
    card: {
      modal: false,
      attendee: null,
    },
    photobooth: {
      photo_booth_config_is_loading: false,
      photo_booth_config: { backgrounds: [] },
      photobooth_config_id: null,
      gallery_modal: false,
      modal: false,
    },
    profile: {
      tab: 'profile',
      modal: false,
      attendee: null,
    },
    rep_dashboard: {
      tab: 'in_stand',
      currentSelectedSlug: '',
      modal: false,
      attendee: null,
    },
    mod_dashboard: {
      tab: 'attendees',
      modal: false,
    },
    mod_dashboard_custom_form: {
      tab: 'attendees',
      modal: false,
    },
    userProfile: {
      isUpdating: false,
    },
    userCards: {
      isExporting: false,
    },
    socket: {
      onlyThisLocation: false,
      onlyActive: false,
      filters: [],
      globalChannels: [],
      users: [],
      usersLocation: [],
    },
    show: {
      isLoading: true,
      data: {
        channels: [],
        elements: [],
        reps: [],
      },
    },
    customform: {
      modal: false,
      form: {},
    },
  }
}

// getters
const getters = {
  currentBreakPointKey(state) {
    return state.constants.is_desktop ? 'desktop' : 'mobile'
  },
  canOpenModModal(state, getters, rootState, rootGetters) {
    const { is_hybrid } = state.constants.registry
    const validRole = [
      rootGetters['session/isAdmin'],
      rootGetters['session/isModerator'],
    ].includes(true)
    return is_hybrid && validRole
  },
  isRealtimeEnabled(state, getters, rootState, rootGetters) {
    const { disable_real_time } = state.constants.registry
    const isAdmin = rootGetters['session/isAdmin']
    return !disable_real_time || (disable_real_time && isAdmin)
  },
  canOpenCustomFormModal(state, getters, rootState, rootGetters) {
    const { show_list_entries } = state.constants.registry
    const validRole = [
      rootGetters['session/isAdmin'],
      rootGetters['session/isModerator'],
    ].includes(true)
    return show_list_entries && validRole
  },
  virtualRoomCurrentElements(state, getters) {
    return state.show.data.elements
      .filter((row) =>
        row.breakpoints.split(',').includes(getters.currentBreakPointKey),
      )
      .map((row) => {
        if (row.element_type.name == ElementTypeKeys.slider) {
          const slider_item_slides = row.slider_item_slides.filter((row) => {
            if (row.duration) {
              return isHappening(row.duration)
            }
            return true
          })
          return { ...row, slider_item_slides }
        }

        return row
      })
  },
  emebedContentElement(_, getters) {
    const emebedVideoElement = getters.virtualRoomCurrentElements.find(
      (row) =>
        row.element_type.name == ElementTypeKeys.video &&
        row.video_item_type.name == VideoKeys.element,
    )
    if (!emebedVideoElement) {
      return null
    }
    return emebedVideoElement.content_element
  },
  plainMetricsPayload(state, getters) {
    const content_library_element_id = currentContentLibraryElementId(
      state,
      getters,
    )
    return {
      nav_count: state.constants.nav_count,
      uuid_tracker: state.constants.uuid_tracker,
      device_width: window.innerWidth,
      device_height: window.innerHeight,
      room_width: state.constants.room_width,
      room_height: state.constants.room_height,
      virtual_room_id: state.show.data.id,
      content_library_element_id,
    }
  },
  styles_element(state) {
    return state.show.data.elements.reduce((row, curr) => {
      const multiplier = state.constants.room_width / 1000
      row[curr.uuid] = {
        width: parseInt(curr.desktop_width) * multiplier + 'px',
        height: parseInt(curr.desktop_height) * multiplier + 'px',
        top: parseInt(curr.desktop_y) * multiplier + 'px',
        left: parseInt(curr.desktop_x) * multiplier + 'px',
      }
      return row
    }, {})
  },
  eventConfig(state) {
    return state.constants.event_configs.data
  },
  eventRegistryConfig(state) {
    return state.constants.registry.configs.find(
      (row) =>
        row.language.name ===
        state.constants.event_configs.data.current_config_language_key,
    )
  },
  currentForm(state) {
    if (isObjectEmpty(state.constants.registry)) {
      return {}
    }
    return state.constants.registry.forms.find(
      (row) =>
        row.language.name ===
        state.constants.event_configs.data.current_config_language_key,
    )
  },

  getRoomFromConstants: (state) => (id) => {
    return state.constants.virtual_rooms.find((room) => room.id === id)
  },
  checkUser: (state) => (id) => {
    console.log('socket: ', state.socket.users)
    return state.socket.users.find((user) => user.id === id)
  },
  idsUsers: (state) => {
    console.log('plain map: ', state.socket.users)
    return state.socket.users.map((user) => user.id)
  },
  myRepRooms: ({ constants }) => {
    return constants.my_rooms
  },
}

// actions
const actions = {
  async getRepRoomVisitors({ state }) {
    const slug = state.rep_dashboard.currentSelectedSlug
    const uuid = state.constants.registry.uuid
    return EventServiceInstance.getRepRoomVisitors(slug, uuid)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async openBussinessCard({ state, commit }, { uuid = '' }) {
    // console.log(state.socket.users)
    const user = state.socket.users.find((row) => row.uuid === uuid)
    // console.log(user)
    commit('setCard', { attendee: user, modal: true })
  },
  getEventUsers: debounce(function ({ commit, state }) {
    if (state.constants.users_loading) {
      console.log('stops')
      return
    }
    commit('setConstants', {
      users_loading: true,
    })
    return EventServiceInstance.getUsers()
      .then(({ data: { users } }) => {
        commit('setSocket', {
          users,
        })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setConstants', {
          users_loading: false,
          needs_refershing: false,
        })
      })
  }, 2000),
  gestEventUsers({ commit, state }) {
    if (state.constants.users_loading) {
      console.log('stops')
      return
    }
    commit('setConstants', {
      users_loading: true,
    })
    return EventServiceInstance.getUsers()
      .then(({ data: { users } }) => {
        commit('setSocket', {
          users,
        })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setConstants', {
          users_loading: false,
          needs_refershing: false,
        })
      })
  },
  getEventUsersHybrid({ commit }) {
    return EventHybridServiceInstance.getUsersHybrid()
      .then(({ data: { users } }) => {
        commit('setSocket', { users })
      })
      .finally(() => {
        commit('setConstants', {
          needs_hybrid_refershing: false,
        })
      })
  },
  // ------ Contants ------ // 
  async loadConstants({ commit, state }) {
    // if (!state.constants.constantsLoaded) {
    commit('setConstants', { constantsLoaded: false })
    const { data } = await EventServiceInstance.init({
      params: {
        version: state.constants.is_preview ? 'rehersal' : 'live',
      },
    })
    const {
      registry,
      event_connections,
      event_users_block,
      profiles,
      favorites,
      rep_rooms,
      license,
      version,
      qrlink,
      threads,
      channels,
      notifications,
      quick_notes,
      support_user,
    } = data
    commit('setConstants', {
      registry,
      event_connections,
      event_users_block,
      profiles,
      favorites,
      rep_rooms,
      license,
      version,
      qrlink,
      threads,
      channels,
      notifications,
      quick_notes,
      support_user,
    })
    if (rep_rooms.length > 0) {
      commit('setRepDashboard', {
        currentSelectedSlug: rep_rooms[0].slug,
      })
    }
    commit('setConstants', { constantsLoaded: true })
  },
  // ------ Load Constant V2 ------ // 
  async loadContantsV2({dispatch, getters}) {
    const promises = [
      dispatch('loadEventConfigs'),
      dispatch('loadVirtualRooms'),
    ]
    if (getters.isRealtimeEnabled) {
      promises.push(dispatch('loadUsersAction'))
    }
    return Promise.all(promises)
    .catch((err) => {
      console.log('🚀 ~ file: event.js:382 ~ loadContantsV2 ~ err:', err)
    })
  },
  // ------ Virtual Rooms ------ // 
  async loadVirtualRooms({ commit, state }) {
    const { data } = await EventServiceInstance.initVirtualRoom({
      params: { version: state.constants.is_preview ? 'rehersal' : 'live' },
    })
    const { virtual_rooms } = data
    commit('setConstants', { virtual_rooms })
  },
  // ------ Event Configs ------ // 
  async loadEventConfigs({ commit, state }) {
    const { data } = await EventServiceInstance.initEventConfigs({
      params: { version: state.constants.is_preview ? 'rehersal' : 'live' },
    })
    const { event_configs } = data
    event_configs.forEach((config_item) => {
      const formated_row = {[config_item.config_language.name]: config_item}
      commit('setNavItems', {
        current_config_language_key: config_item.config_language.name,
        nav_items: config_item.nav_items,
      })
      commit('setConfig', formated_row)
    })
  },
  async loadUsersAction({ commit, state }) {
    const { data } = await EventServiceInstance.initUsersAction({
      params: { version: state.constants.is_preview ? 'rehersal' : 'live' },
    })
    const { users } = data
    commit('setSocket', { users })
  },
  async show({ commit }, { id }) {
    // commit("setAppIsLoading", true, { root: true });

    commit('setShow', { isLoading: true })

    return EventServiceInstance.getRoom(id)
      .then(({ data }) => {
        commit('setShow', { data: data.data })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        // commit("setAppIsLoading", false, { root: true });
      })
  },
  async createFavorite({ commit }, body) {
    // const { slug } = state.constants.registry
    return EventServiceInstance.createFavorite(body)
      .then(({ data }) => {
        const { favorites } = data
        commit('setConstants', { favorites })
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async delteFavorite({ commit }, body) {
    const { favorite_id } = body
    return EventServiceInstance.deleteFavorite(favorite_id)
      .then(({ data }) => {
        const { favorites } = data
        commit('setConstants', { favorites })
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async createEventConnection({ state, commit }, body) {
    const { slug } = state.constants.registry
    return EventServiceInstance.createEventConnection(slug, body)
      .then(({ data }) => {
        const { event_connections } = data
        commit('setConstants', { event_connections })
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async deleteEventConnection({ state, commit }, body) {
    const { slug } = state.constants.registry
    return EventServiceInstance.deleteEventConnection(slug, body)
      .then(({ data }) => {
        const { event_connections } = data
        commit('setConstants', { event_connections })
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  // ------------------ Matchmaking ------------------ //
  getAutomaticMatch(__, payload) {
    return EventServiceInstance.getAutomaticMatch(payload)
      .then(({data}) => {
        return data
      })
  },
  getFilterMatch(__, payload) {
    return EventServiceInstance.getFilterMatch(payload)
      .then(({data}) => {
        return data
      })
  },
  // ------------------ Notifications ------------------ //
  async getNotifications({commit, state}) {
    const { forms } = state.constants.registry
    return EventNotificationsInstance.notifications({
      'forms': forms,
    })
    .then(({ data }) => {
      const { notifications } = data
      commit('setConstants', { notifications })
    })
  },
  async deleteNotification({commit, state}, body) {
    const { forms } = state.constants.registry
    return EventNotificationsInstance.deleteNotification(body.id, {
      'forms': forms,
    })
      .then(({ data }) => {
        const { notifications } = data
        commit('setConstants', { notifications })
      })
  },
  async deleteAll({commit, state}) {
    const { forms } = state.constants.registry
    return EventNotificationsInstance.deleteAll({
      'forms': forms,
    })
      .then(({ data }) => {
        const { notifications } = data
        commit('setConstants', { notifications })
      })
  },
  async updateMark({commit, state}, body) {
    const { forms } = state.constants.registry
    return EventNotificationsInstance.updateMark(body.id, {
      'forms': forms,
    })
      .then(({ data }) => {
        const { notifications } = data
        commit('setConstants', { notifications })
      })
  },
  async newNotificationMessage(_, body) {
    return EventNotificationsInstance.newMessage(body)
  },
  async broadcastAfterLogin(_, body) {
    return EventNotificationsInstance.broadcastAfter(body)
  },
  // ------------------ Quick Notes ------------------ //
  async quickNotesStore({ commit }, body) {
    return quickNotesServiceInstance.store(body)
    .then(({ data }) => {
      const { quick_notes } = data
      commit('setConstants', { quick_notes })
    })
  },
  async quickNotesDelete({commit}, body) {
    return quickNotesServiceInstance.delete(body)
    .then(({data}) => {
      const { quick_notes } = data
      commit('setConstants', { quick_notes })
    })
  },
  async quickNotesHighlight({commit}, body) {
    return quickNotesServiceInstance.highlight(body)
    .then(({data}) => {
      const { quick_notes } = data
      commit('setConstants', { quick_notes })
    })
  },
  async quickNoteSendEmail(__, body) {
    return quickNotesServiceInstance.email(body)
    .then(() => {
      console.time('🚀 send')
    })
  },
  // ------------------ Quick Notes ------------------ //
  // ------------------ Calendar Appointment ------------------ //
  async registryCalendarIndex({state}, { payload }) {
    const registry = state.constants.registry.id
    return RegistriesCalendarsService.index(registry, payload)
      .then(({data}) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async destroyCalendarItems(_, {payload, id, calendar}) {
    return CalendarsItemsService.destroy(calendar, id, payload)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async getRegistryCalendarRequests({ payload }) {
    return CalendarRequestService.requestIndex(payload)
    .then(({ data }) => {
      return data
    })
  },
  async getRegistryCalendarReceived({ payload }) {
    return CalendarRequestService.receivedIndex(payload)
    .then(({ data }) => {
      return data
    })
  },
  async acceptedReceivedCalendar(_, { body = {} }) {
    return CalendarRequestService.receivedAccepted(body)
    .then(({ data }) => {
      return data
    })
  },
  // ------------------ Calendar Appointment ------------------ //
  async accessControlStore({ state, commit }, { payload }) {
    const registry_id = state.constants.registry.id
    const form = {
      ...payload,
    }
    return AccessControlsServiceInstance.store(registry_id, form)
      .then(({ data }) => {
        // Acual usuario bloqueado 
        const userBlocked = data.data
        // Todos los usuarios bloqueados
        const rows = state.constants.event_users_block
        // Actualizar la lista
        const event_users_block = [
          ...rows,
          userBlocked,
        ]
        commit('setConstants', { event_users_block })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async updateProfile({ commit }, { uuid = '', body = {} }) {
    commit('setUserProfile', { isUpdating: true })
    console.log(body)
    return EventServiceInstance.updateUserProfile(uuid, body)
      .then(({ data }) => {
        const {
          profiles,
          user: { email, first_name, last_name, avatar },
        } = data
        commit('setConstants', {
          profiles,
        })
        commit(
          'session/setUser',
          {
            email,
            first_name,
            last_name,
            avatar,
          },
          { root: true },
        )
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setUserProfile', { isUpdating: false })
      })
  },
  async updateProfileMod(__, { uuid = '', user_id = '' , body = {} }) {
    return EventServiceInstance.updateUserProfileMod(uuid, user_id, body)
    .then(() => {
      console.log('Informacion actualizada')
    })
    .catch((err) => {
      throw formErrorsHandler(err)
    })
  },
  async getEventLeaderboardStats() {
    return EventServiceInstance.getEventLeaderboardStats()
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async showPhotoboothConfig(
    { commit },
    { photo_booth_config = '', body = {} },
  ) {
    return EventServiceInstance.showPhotoboothConfig(photo_booth_config, body)
      .then(({ data: { data: photo_booth_config }, ...data }) => {
        commit('setPhotobooth', {
          photo_booth_config,
        })
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async indexPhotoboothConfigGallery(
    _,
    { photo_booth_config = '', body = {} },
  ) {
    return EventServiceInstance.indexPhotoboothConfigGallery(
      photo_booth_config,
      body,
    )
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async storePhotoboothPhoto(_, { uuid = '', body = {} }) {
    return EventServiceInstance.storePhotoboothPhoto(uuid, body)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  async indexPhotoboothGallery(_, { registry_id = '', body = {} }) {
    return EventServiceInstance.indexPhotoboothGallery(registry_id, body)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  updatePasswordProfile(_, { uuid = '', body = {} }) {
    // commit('setAppIsLoading', true, { root: true })
    return EventServiceInstance.storeNewPassword(uuid, body)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        // commit('setAppIsLoading', false, { root: true })
      })
  },
  exportEventConnections({ state, commit }) {
    commit('setUserCards', { isExporting: true })
    const { uuid } = state.constants.registry
    return EventServiceInstance.exportEventConnection(uuid)
      .then((res) => {
        axiosDownloadable(res, 'tarjetas_')
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setUserCards', { isExporting: false })
      })
  },
}

// mutations
const mutations = {
  setCustomForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.customform[k] = obj[k]))
  },
  setNavItems(state, { nav_items, current_config_language_key }) {
    state.constants.nav_items.data[current_config_language_key] = nav_items
  },
  setConfig(state, obj) {
    Object.keys(obj).forEach(
      (k) => (state.constants.event_configs.data[k] = obj[k]),
    )
  },
  setConstants(state, obj) {
    Object.keys(obj).forEach((k) => (state.constants[k] = obj[k]))
  },
  setSocket(state, obj) {
    if (JSON.stringify(obj.users) !== JSON.stringify(state.socket.users)) {
      Object.keys(obj).forEach((k) => (state.socket[k] = obj[k]))
    }
  },
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
  setFlexible(state, obj) {
    Object.keys(obj).forEach((k) => (state.flexible[k] = obj[k]))
  },
  setUserProfile(state, obj) {
    Object.keys(obj).forEach((k) => (state.userProfile[k] = obj[k]))
  },
  setUserCards(state, obj) {
    Object.keys(obj).forEach((k) => (state.userCards[k] = obj[k]))
  },
  setCard(state, obj) {
    Object.keys(obj).forEach((k) => (state.card[k] = obj[k]))
  },
  setPhotobooth(state, obj) {
    Object.keys(obj).forEach((k) => (state.photobooth[k] = obj[k]))
  },
  setProfile(state, obj) {
    Object.keys(obj).forEach((k) => (state.profile[k] = obj[k]))
  },
  setRepDashboard(state, obj) {
    Object.keys(obj).forEach((k) => (state.rep_dashboard[k] = obj[k]))
  },
  setModDashboard(state, obj) {
    Object.keys(obj).forEach((k) => (state.mod_dashboard[k] = obj[k]))
  },
  setModDashboardCustomForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.mod_dashboard_custom_form[k] = obj[k]))
  },
  setSearch(state, obj) {
    Object.keys(obj).forEach((k) => (state.search[k] = obj[k]))
  },
  setIframeItem(state, obj) {
    Object.keys(obj).forEach((k) => (state.iframeItem[k] = obj[k]))
  },
  setContentItem(state, obj) {
    Object.keys(obj).forEach((k) => (state.contentItem[k] = obj[k]))
  },
  setContentVideo(state, obj) {
    Object.keys(obj).forEach((k) => (state.contentVideo[k] = obj[k]))
  },
  setActiveInmediatePopUp(state, obj) {
    Object.keys(obj).forEach((k) => (state.activeInmediatePopUp[k] = obj[k]))
  },
  setContentWindow(state, obj) {
    Object.keys(obj).forEach((k) => (state.contentWindow[k] = obj[k]))
  },
  setSimpleVideoItem(state, obj) {
    Object.keys(obj).forEach((k) => (state.simpleVideoItem[k] = obj[k]))
  },
}

export const Event = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
