import $axios from '@/axios-instance.js'
import $ax from '@/axios-silent'

export default class EventService {
  baseUrl = '/api/v1/event'

  // Create
  init(params) {
    return $axios.get(`${this.baseUrl}/constants`, params)
  }
  // Init Virtual Rooms
  initVirtualRoom(params) {
    return $axios.get(`${this.baseUrl}/constants-vr`, params)
  }
  // Init Virtual Rooms
  initEventConfigs(params) {
    return $axios.get(`${this.baseUrl}/constants-ec`, params)
  }
  // Init Virtual Rooms
  initUsersAction(params) {
    return $axios.get(`${this.baseUrl}/constants-ua`, params)
  }

  // Read all - findAll
  getRoom(slug, params) {
    return $axios.get(`${this.baseUrl}/virtual-room/${slug}`, params)
  }
  getUsers(params) {
    return $axios.get(`${this.baseUrl}/users`, params)
  }
  // Donovann
  getUsersChat(params) {
    return $ax.get(`${this.baseUrl}/users`, params)
  }
  // -------------------- Matchmaking -------------------- //
  getAutomaticMatch(params) {
    return $ax.get(`${this.baseUrl}/user-match`, params)
  }
  getFilterMatch(params) {
    return $ax.get(`${this.baseUrl}/filter-match`, params)
  }
  // -------------------- Matchmaking -------------------- //
  getRepRoomVisitors(slug, uuid, params) {
    return $axios.get(`${this.baseUrl}/visitors/${slug}/${uuid}`, params)
  }
  exportEventConnection(uuid) {
    return $axios.get(`${this.baseUrl}/connections/${uuid}/export`, {
      responseType: 'blob',
    })
  }
  createEventConnection(slug, params) {
    return $axios.post(`${this.baseUrl}/connections/${slug}`, params)
  }
  deleteEventConnection(slug, params) {
    return $axios.post(`${this.baseUrl}/connections/${slug}`, {
      ...params,
      _method: 'DELETE',
    })
  }
  updateUserProfile(registry_form_uuid, params) {
    return $axios.post(`${this.baseUrl}/profile/${registry_form_uuid}`, params)
  }
  updateUserProfileMod(registry_form_uuid, user_id, params) {
    return $axios.post(`${this.baseUrl}/profile-mod/${registry_form_uuid}/${user_id}`, params)
  }
  storePhotoboothPhoto(registry_form_uuid, params) {
    return $axios.post(
      `${this.baseUrl}/photobooth/${registry_form_uuid}`,
      params,
    )
  }
  showPhotoboothConfig(photo_booth_config, params) {
    return $axios.get(
      `${this.baseUrl}/photobooth-config/${photo_booth_config}`,
      params,
    )
  }

  indexPhotoboothConfigGallery(photo_booth_config, params) {
    return $axios.get(
      `${this.baseUrl}/photobooth-config/${photo_booth_config}/gallery`,
      params,
    )
  }
  indexPhotoboothGallery(registry_id, params) {
    return $axios.get(
      `${this.baseUrl}/registry/${registry_id}/photobooth`,
      params,
    )
  }
  storeNewPassword(registry_form_uuid, params) {
    return $axios.post(
      `${this.baseUrl}/profile/${registry_form_uuid}/password`,
      params,
    )
  }

  getEventLeaderboardStats(params) {
    return $axios.get(`${this.baseUrl}/leaderboard-stats`, params)
  }

  createFavorite(params) {
    return $axios.post(`${this.baseUrl}/favorites`, params)
  }

  deleteFavorite(favorite_id) {
    return $axios.post(`${this.baseUrl}/favorites/${favorite_id}`, {
      _method: 'DELETE',
    })
  }
}
export const EventServiceInstance = new EventService()
