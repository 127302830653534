<template>
    <div>
        <v-dialog
          v-model="open"
          persistent
          scrollable
          max-width="1024px"
          content-class="tw-rounded-none"
        >
            <v-card class="fill-height tw-rounded-xl tw-border-0 tw-shadow-md">
                <v-container class="tw-bg-primary-500 tw-px-5 tw-flex tw-items-center tw-justify-between">
                    <h4 class="tw-text-2xl tw-font-bold tw-text-primary-50">
                        {{$t('app.edit')}}
                    </h4>
                    <v-btn
                        fab
                        x-small
                        class="focus:tw-outline-none focus:tw-ring"
                        @click="closeModal"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-container>
                <v-card-text>
                    <v-form 
                        ref="form" 
                        v-model="valid" 
                        lazy-validation
                        :disabled="isUpdating"
                        autocomplete="off"
                        @submit.prevent="updateProfile"
                    >
                        <!-- Account -->
                        <section :id="'my_account'" class="tw-pt-6">
                           <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 md:tw-gap-4">
                                <div>
                                    <v-text-field
                                        v-model="form.first_name" 
                                        :label="$t('forms.first_name')" 
                                        :rules="[rules.testEmpty]"
                                        type="text" 
                                        outlined 
                                        validate-on-blur 
                                        required 
                                        rounded 
                                        class="tw-text-sm" 
                                    />
                                </div>
                                <div>
                                    <v-text-field
                                        v-model="form.last_name" 
                                        :label="$t('forms.last_name')" 
                                        :rules="[rules.testEmpty]"
                                        type="text"
                                        outlined 
                                        validate-on-blur 
                                        required 
                                        rounded 
                                        class="tw-text-sm" 
                                    />
                                </div>
                                <div>
                                    <v-text-field
                                        ref="email" 
                                        v-model="form.email" 
                                        prepend-inner-icon="alternate_email"
                                        name="email" 
                                        :label="$t('forms.email')" 
                                        type="email" 
                                        outlined 
                                        validate-on-blur 
                                        :rules="emailRules"
                                        rounded 
                                        class="tw-text-sm" 
                                    />
                                </div>
                            </div>
                        </section>
                        <v-divider />
                        <div v-if="hasDefaultFields" class="tw-mt-2 tw-flex tw-py-4">
                            <div class="tw-mt-6 tw-flex-grow tw-space-y-2">
                                <div class="tw-grid tw-grid-1 md:tw-grid-cols-2 tw-gap-x-6 tw-gap-y-2">
                                <template v-for="default_field in default_fields">
                                    <div v-if="default_field.is_active" :key="default_field.name">
                                    <select-countries
                v-if="default_field.name === 'country_code'" v-model="form[default_field.name]"
                                        :label="default_field.label" :rules="[rules.testEmpty]" outlined rounded validate-on-blur />
                                    <v-text-field
                v-else v-model="form[default_field.name]" :label="default_field.label"
                                        :name="default_field.name" :rules="[rules.testEmpty]" type="text" outlined validate-on-blur
                                        rounded class="tw-text-sm" />
                                    </div>
                                </template>
                                </div>
                            </div>
                        </div>
                        <v-divider v-if="hasDefaultFields" />
                        <div v-if="hasCustomFields" class="pt-6">
                            <DynamicFields :fields="registry_form_submission" />
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <AppBtn :disabled="isUpdating" :loading="isUpdating" color="primary" icon="check" @click="updateProfile">
                        {{ $t('app.save') }}
                    </AppBtn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import DynamicFields from '@/components/DynamicFields.vue'
import { addModelText, onlyNumber } from '@/utils/Settings'
import AppBtn from '@/components/App/AppBtn.vue'

export default {
    name: 'EditProfileModal',
    components: {
        DynamicFields,
        AppBtn,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        registry: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        attendee: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        form: {
            avatar:
                'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=320&amp;h=320&amp;q=80',
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            password_confirmation: '',
            company: '',
            title: '',
            country_code: '',
            address: '',
            city: '',
            phone_number: '',
        },
        registry_form_submission: [],
        default_fields: [],
        rules: {
            numericZero: (v) => !!parseFloat(v) || 'Campo no puede ser 0',
            testEmpty: (value) => !!value || 'Campo no puede estar vacio.',
            min: (v) => v.length >= 8 || 'Min 8 characters',
            emailMatch: () => "The email and password you entered don't match",
        },
        valid: true,
        emailRules: [
            (v) => !!v || 'E-mail no puede estar vacio',
            (v) => /.+@.+/.test(v) || 'E-mail no es valido',
        ],
        onlyNumber,
        isUpdating: false,
    }),
    computed: {
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            } ,
        },
        current_form() {
            return this.registry.forms.find(
                (row) => row.language.name === this.current_language_key,
            )
        },
        ...mapState({
            current_language_key: (state) => state.event.constants.event_configs.data.current_config_language_key,
        }),
        hasDefaultFields() {
            return this.default_fields.filter((row) => row.is_active).length > 0
        },
        hasCustomFields() {
            return this.registry_form_submission.length > 0
        },
    },
    mounted() {
        this.initForm()
    },
    methods: {
        updateProfile() {
            if (!this.$refs.form.validate()) {
                return
            }
            const payload = {
                uuid: this.current_form.uuid,
                user_id: this.user.id,
                body: {
                    ...this.user,
                    ...this.form,
                    registry_form_submission: addModelText(this.registry_form_submission),
                },
            } 
            this.$store
                .dispatch('event/updateProfileMod', payload)
                .then(() => {
                    // this.initForm()
                    this.$emit('attendee-updated', this.user.uuid)
                    this.$store.dispatch('notifications/addNotification', {
                        title: this.$t('pages.event.profile.updated.profile.title'),
                        body: this.$t('pages.event.profile.updated.profile.body'),
                    })
                })
                .catch((err) => {
                    // this.errorAlert = true
                    // this.errorMsg = err
                    console.log('error', err)
                })
        },
        initForm() {
            const current_form = JSON.parse(JSON.stringify(this.current_form))
            this.registry_form_submission = current_form.fields
            this.default_fields = current_form.default_fields.filter(
                (row) => row.name !== 'email',
            )
            this.setInitialFormData()
            this.setAdditionalData()
        },
        setInitialFormData() {
            this.form.email = this.user.email
            this.form.avatar = this.user.avatar
            this.form.first_name = this.user.first_name
            this.form.last_name = this.user.last_name

            this.form.company = this.attendee.company
            this.form.title = this.attendee.title
            this.form.country_code = this.attendee.country_code
            this.form.address = this.attendee.address
            this.form.city = this.attendee.city
            this.form.phone_number = this.attendee.phone_number
        },
        setAdditionalData() {
            if (
                !this.attendee.registry_form_submission ||
                !this.registry_form_submission
            ) {
                return
            }
            this.registry_form_submission = this.registry_form_submission.map(
                (row) => {
                const registry_form_submission =
                    this.attendee.registry_form_submission.find(
                    (user_row) => user_row.name == row.name,
                    )
                const model = this.formSubmissionModel(registry_form_submission)
                const model_multiple = this.formSubmissionModelMultiple(
                    registry_form_submission,
                )
                return { ...row, model, model_multiple }
                },
            )
        },
        formSubmissionModel(registry_form_submission) {
            return registry_form_submission.model
        },
        formSubmissionModelMultiple(registry_form_submission) {
            return registry_form_submission.model_multiple
        },
        closeModal() {
            this.open = false
        },
    },
}
</script>