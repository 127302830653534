<template>
  <v-card class="tw-rounded-3xl tw-shadow-md" white>
    <!-- tw-via-primary-500 -->
    <v-container class="tw-p-0" fluid>
      <!-- <v-row class="px-4 pt-6"> -->
      <div class="tw-grid tw-grid-cols-1 tw-border-b tw-pt-3 lg:tw-grid-cols-2">
        <div
          class="
            tw-col-span-2 tw-row-start-2 tw-flex tw-items-end
            lg:tw-col-span-1 lg:tw-row-start-auto
          "
        >
          <v-tabs v-if="!isMod" v-model="filter_type" show-arrows>
            <v-tab
              v-for="filter_tab in filter_tabs"
              :key="filter_tab.text"
              :href="'#' + filter_tab.value"
            >
              <span class="tw-text-xs">
                {{ filter_tab.text }}
              </span>
            </v-tab>
          </v-tabs>
        </div>
        <div
          class="
            tw-col-span-2
            lg:tw-flex
            tw-grid-cols-1 tw-gap-2 tw-px-4 tw-pb-1
            lg:tw-col-span-1 lg:tw-grid-cols-12
          "
        >
          <!-- <v-select
            v-model="filter_selected"
            :items="filters"
            :label="$t('admin.app.filter_by')"
            prepend-inner-icon="sort"
            hide-details
            outlined
            rounded
            dense
          /> -->
          <!-- <v-select
            v-if="filter_selected == 'is_online'"
            v-model="search_enum"
            :items="search_enums"
            :label="$t('admin.app.filter_by')"
            prepend-inner-icon="sort"
            hide-details
            outlined
            rounded
            dense
          /> -->
          <!-- Recargar -->
          <div v-if="isMod" class="tw-text-center">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  fab
                  small
                  plain
                  :loading="loading"
                  v-bind="attrs"
                  v-on="on"
                  @click="reloadUsers"
                >
                  <v-icon>refresh</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('app.update') }}</span>
            </v-tooltip>
          </div>
          <!-- End Recargar -->
          <!-- Certificates -->
          <div v-if="!isMod && show.is_send_certificate" class="tw-text-center">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  fab
                  small
                  plain
                  :to="{
                    name: 'admin.registries.participants.certificates',
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>workspace_premium</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('admin.app.certificate') }}</span>
            </v-tooltip>
          </div>
          <!-- End Certificates -->
          <!-- Credentials -->
          <div v-if="!isMod && show.is_hybrid" class="tw-text-center">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  fab
                  small
                  plain
                  :to="{
                    name: 'admin.registries.participants.credentials',
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>badge</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('admin.app.credential') }}</span>
            </v-tooltip>
          </div>
          <!-- End Credentials -->
          <v-text-field
            v-model="search"
            outlined
            rounded
            append-icon="search"
            :label="$t('app.search')"
            hide-details
            dense
            class="tw-text-xs tw-pb-2 lg:tw-pb-2"
          />
          <v-select
            v-if="!isMod"
            v-model="idAction"
            :items="actionsAttendes"
            :label="$t('admin.ticket.items.action.label')"
            item-text="text"
            item-value="value"
            hide-details
            outlined
            rounded
            dense
            class="tw-text-xs tw-pb-2 lg:tw-pb-2"
            clearable
            @click:clear="onClearClicked()"
            @change="activeSelectClass"
          />
          <TicketAutocomplete
            v-if="is_active_select && !isMod"
            v-model="registry_ticket_id"
            :tickets="registry_ticket_language"
            dense
            class="tw-text-xs"
          />
          <v-btn
            v-if="!isMod"
            color="primary"
            fab
            small
            plain
            class="tw-normal tw-w-full lg:tw-w-auto"
            @click="applyChanges"
          >
            <!-- <span class="tw-mr-2">
              {{$t('admin.ticket.items.action.button')}}
            </span> -->
            <v-icon>check_circle</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- </v-row> -->
    </v-container>
    <v-container class="tw-overflow-hidden tw-p-0" fluid>
      <v-data-table
        v-model="selected"
        :show-select="actionActive"
        :search="search"
        :headers="headers"
        :items="formatedRows"
        :options.sync="options"
        :server-items-length="totalRows"
        :loading="loading"
        class="elevation-1"
        :footer-props="footer_props"
        :class="{ mobile: $vuetify.breakpoint.mdAndDown }"
      >
        <template #body="{ items }">
          <transition mode="out-in" name="fade">
            <tbody v-if="items.length" key="main-table">
              <tr
                v-for="item in items"
                :key="item.id"
                class="tw-transition sm:tw-py-4 tw-cursor-pointer"
                :class="{ 'tw-opacity-75 ': loading }"
                @click="showItem(item)"
              >
                <!-- Donovann: Checkbox -->
                <td v-if="actionActive">
                  <v-checkbox v-model="selected" :value="item" />
                </td>
                <td>
                <!-- <td> -->
                  <div class="py-4 tw-flex">
                    <div class="tw-flex-shrink-0">
                      <v-badge
                        bordered
                        bottom
                        overlap
                        :color="item.is_online ? 'success' : '#bbbbbb'"
                        dot
                      >
                        <v-avatar size="40">
                          <v-img :src="item.avatar" contain>
                            <template #placeholder>
                              <v-row
                                class="fill-height ma-0 transparent"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  size="32"
                                  indeterminate
                                  color="primary"
                                />
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-badge>
                    </div>
                    <div class="tw-ml-4 tw-flex tw-items-center">
                      <div>
                        <quinary-title :dark="false">
                          {{ item.fullName }}
                        </quinary-title>
                        <p class="tw-mb-0 tw-text-xs tw-text-gray-500">
                          {{ item.email }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td v-if="show.is_multi_ticket">
                  <div class="tw-flex tw-justify-start">
                    <v-chip color="primary" outlined>
                      {{ item.formatedTicket }}
                    </v-chip>
                  </div>
                </td>
                <td v-if="activeFieldHeader">
                  <span class="tw-text-xs">
                    {{ item.fields }}
                  </span>
                </td>
                <td>
                  <div
                    class="tw-flex tw-flex-col tw-justify-start tw-space-y-1"
                  >
                    <CommonBadge
                      v-for="attendee in item.attendees"
                      :key="attendee.id"
                    >
                      {{ $t(`admin.enums.${attendee.language.name}`) }}
                    </CommonBadge>
                  </div>
                </td>
                <td v-if="!isMod">
                  <div class="tw-flex tw-justify-start">
                    <span class="tw-font-normal tw-text-gray-500 tw-text-xs">
                      {{ item.formatedRoles }}
                    </span>
                  </div>
                </td>
                <!-- <td>
                  <div class="tw-flex tw-justify-start">
                    <StatusBadge
                      :is-active="!!item.is_online"
                      :active-text="$t('app.online')"
                    />
                  </div>
                </td> -->
                <td v-if="registry.is_hybrid">
                  <div class="tw-flex tw-justify-start">
                    <StatusBadge
                      :is-active="
                        !!item.has_checkout ? false : !!item.has_checkin
                      "
                      :inactive-text="
                        $t('pages.event.mod_dash.badge.unchecked')
                      "
                      :active-text="$t('pages.event.mod_dash.badge.checked')"
                    />
                  </div>
                </td>
                <td v-if="!isMod">
                  <div class="tw-flex tw-justify-end">
                    <span>
                      {{ item.date }}
                    </span>
                  </div>
                </td>
                <td>
                  <v-btn
                    fab
                    text
                    small
                    class="
                      tw-text-gray-300
                      focus:tw-text-gray-600
                      focus:tw-outline-none
                      focus:tw-ring-primary-500
                    ">
                    <!-- @click="showItem(item)" -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="tw-h-6 tw-w-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </v-btn>
                </td>
                <!-- <td>
                  <div class="flex tw-items-center tw-space-x-2">
                    <v-icon class="tw-cursor-pointer tw-text-secondary-500" @click="showItem(item)">person_search</v-icon>
                    <v-icon class="tw-cursor-pointer tw-text-gray-400" @click="checkInBz(item)">check_circle_outline</v-icon>
                    <v-icon class="tw-cursor-pointer tw-text-green-500">check_circle_outline</v-icon>
                    <v-icon class="tw-cursor-pointer tw-text-blue-500">print</v-icon>
                  </div>
                </td> -->
              </tr>
            </tbody>
            <tbody v-if="!items.length && !loading" key="zero_table">
              <tr>
                <td colspan="8">
                  <v-alert
                    class="mx-8 mt-4"
                    color="primary"
                    border="left"
                    type="info"
                    elevation="2"
                    colored-border
                  >
                    <strong>{{ $t('$vuetify.noDataText') }}</strong>
                  </v-alert>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!items.length && loading" key="loading_table">
              <tr>
                <td colspan="8">
                  <v-alert
                    class="mx-8 mt-4"
                    color="primary darken-1"
                    border="left"
                    type="success"
                    elevation="2"
                    colored-border
                  >
                    <strong>{{ $t('app.loading') }}</strong>
                  </v-alert>
                </td>
              </tr>
            </tbody>
          </transition>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import QuinaryTitle from '@/components/Titles/QuinaryTitle'
import CommonBadge from '@/components/CommonBadge'
import StatusBadge from '@/components/StatusBadge'
import debounce from 'lodash/debounce'
import { ParticipantsService } from '@/services/ParticipantsService'
import EventRegistryResource from '@/services/EventRegistryResource'
import { mapGetters, mapState } from 'vuex'
import { formatterArray } from '@/utils/formatterArray'
import { commonTimeFormat } from '@/utils/dateHelpers'

// import AppBtn from '@/components/App/AppBtn.vue'
// Donovann Ticket Autocomplete component
import TicketAutocomplete from '@/components/TicketAutocomplete.vue'

export default {
  components: {
    CommonBadge,
    QuinaryTitle,
    StatusBadge,
    // AppBtn,
    TicketAutocomplete,
  },
  props: {
    registry: {
      type: Object,
      default: () => ({}),
    },
    isMod: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filter_type: 'all',
    search_enum: 1,
    filter_status: '',
    filter_speciality: '',
    filter_selected: 'email',
    search: '',
    selected: [],
    totalRows: 0,
    rows: [],
    loading: false,
    awaitingSearch: false,
    options: {},
    // Donovann //
    idAction: '',
    is_active_select: false,
    registry_ticket_id: 0,
    actionActive: false,
    // Validando si el field company esta activo en el formulario
    default_fields: [],
    activeFieldHeader: true,
    labelFieldHeader: '',
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('admin.app.name'),
          align: 'start',
          sortable: false,
          value: 'last_name',
        },
        {
          text: this.$t('admin.app.class'),
          align: 'start',
          sortable: false,
          value: 'class',
          hidden: !this.show.is_multi_ticket,
        },
        {
          text: this.labelFieldHeader,
          align: 'start',
          sortable: false,
          value: 'class',
          hidden: !this.activeFieldHeader,
        },
        {
          text: this.$t('app.language'),
          align: 'start',
          sortable: false,
          value: 'roles',
        },
        {
          text: 'Role(s)',
          align: 'start',
          sortable: false,
          value: 'roles',
          hidden: this.isMod,
        },

        // {
        //   text: 'Status',
        //   sortable: true,
        //   value: 'is_online',
        // },
        {
          text: 'Checked',
          sortable: true,
          value: 'is_checkin',
          hidden: !this.registry.is_hybrid,
        },
        {
          text: this.$t('app.created_at'),
          value: 'updated_at',
          sortable: true,
          align: 'end',
          hidden: this.isMod,
        },
        { text: '', value: 'go', sortable: false },
      ].filter((row) => !row.hidden)
    },
    current_form() {
      return this.registry.forms.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    hasDefaultFields() {
      return this.default_fields.find((row) => row.name == 'company')
    },
    search_enums() {
      return [
        { value: 1, text: this.$t('app.actives') },
        { value: 0, text: this.$t('app.inactives') },
      ]
    },
    // Donovann: filter actions to attendees
    actionsAttendes() {
      return [
        {
          value: 1,
          text: this.$t('admin.ticket.items.action.trash'),
        },
        {
          value: 2,
          text: this.$t('admin.ticket.items.action.class'),
          hidden: !this.show.is_multi_ticket,
        },
        {
          value: 3,
          text: this.$t('admin.ticket.items.action.class_d'),
          hidden: !this.show.is_multi_ticket,
        },
      ].filter((row) => !row.hidden)
    },
    filters() {
      return [
        {
          text: this.$t('forms.first_name'),
          value: 'first_name',
        },
        {
          text: this.$t('forms.last_name'),
          value: 'last_name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Status',
          value: 'is_online',
        },
      ]
    },
    filter_tabs() {
      return [
        { text: this.$t('admin.enums.all'), value: 'all' },
        ...this.languages,
      ]
    },
    ...mapGetters({
      languages: ['constants/languages'],
    }),

    formatedRows() {
      return this.rows.map((row) => {
        return {
          ...row,
          formatedRoles: formatterArray().format(
            row.roles.map((role) => this.$t(`app.roles.${role.name}`)),
          ),
          date: commonTimeFormat(row.created_at),
          fields: row.attendees.find(
            (attendee) =>
              attendee.language.name ==
              (this.filter_type == 'all'
                ? this.current_language_key
                : this.filter_type),
          )?.company,
          fullName: `${row.first_name} ${row.last_name}`,
          has_checkin: row.attendees.find(
            (attendee) =>
              attendee.language.name ==
              (this.filter_type == 'all'
                ? this.current_language_key
                : this.filter_type),
          )?.current_checkin_id,
          // row.attendees.some(
          //   (attendee) => attendee.current_checkin_id,
          // ),
          has_checkout: row.attendees.find(
            (attendee) =>
              attendee.language.name ==
              (this.filter_type == 'all'
                ? this.current_language_key
                : this.filter_type),
          )?.current_checkin?.finalized_at,
          // has_checkout: row.attendees.some(
          //   (attendee) => attendee.current_checkin?.finalized_at,
          // ),
          formatedTicket: row.ticket.find(
            (ticket) => ticket.registry_id == this.registry.id,
          )
            ? row.ticket.find(
                (ticket) => ticket.registry_id == this.registry.id,
              ).registry_ticket.ticket_label_key
            : this.$t('admin.ticket.items.labels.no_class'),
        }
      })
    },
    footer_props() {
      const obj = {
        'items-per-page-options': [10, 20, 30, 40, 50],
      }
      return obj
    },
    ...mapState({
      show: (state) => state.registries.show.data,
      current_language_key: (state) => state.registry.show.current_language_key,
    }),
    registry_ticket_language() {
      return this.show.registry_tickets
    },
  },
  watch: {
    options: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_status: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_speciality: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_type: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_selected: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    search_enum: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.getLeads()
      }, 1000),
      deep: true,
    },
  },
  mounted() {
    this.initHeader()
  },
  methods: {
    // ============== Actions v1.1 ============== //
    checkInBz(item) {
      this.$emit('checkinbz', item)
    },
    initHeader() {
      const current_form = JSON.parse(JSON.stringify(this.current_form))
      this.default_fields = current_form.default_fields.filter(
        (row) => row.name !== 'email',
      )
      this.activeFieldHeader = this.hasDefaultFields.is_active
      this.labelFieldHeader = this.hasDefaultFields.label
    },
    showItem(user) {
      this.$emit('show-item', user)
    },
    // Donovann: Using notifications in a function
    messageNot(title, body, status) {
      this.$store.dispatch('notifications/addNotification', {
        title: this.$t(`admin.ticket.items.alerts.label.${title}`),
        body: this.$t(`admin.ticket.items.alerts.description.${body}`),
        status: status,
      })
    },
    // Donovann: If the action is to assign classes activate the following input where it will list all classes of the event
    activeSelectClass() {
      // 1 => Delete
      if (this.idAction == 1) {
        this.actionActive = true
        this.is_active_select = false
        this.registry_ticket_id = null
      }
      // 2 => Assign
      if (this.idAction == 2) {
        this.actionActive = true
        this.is_active_select = true
      }
      // 2 => Check-out
      if (this.idAction == 3) {
        this.actionActive = true
        this.is_active_select = false
      }
    },
    // Donovann: Remove checkboxes to datatable if no action
    onClearClicked() {
      this.actionActive = false
      this.is_active_select = false
      this.registry_ticket_id = null
    },
    // Donovann: Apply changes according to the action
    applyChanges() {
      console.log(this.selected)
      // Validate actions
      if (this.selected.length === 0 || this.idAction === '') {
        this.messageNot('warning', 'warning', 'error')
        return
      }
      // 1 => Delete Attende or Attendees
      if (this.idAction == 1) {
        this.$store
          .dispatch('registries/deleteUsers', {
            payload: {
              attendees: this.selected,
            },
          })
          .then(() => {
            this.getLeads()
          })
          .finally(() => {
            this.messageNot('trash', 'trash', 'success')
          })
      }
      // 2 => Assign class user
      if (this.idAction == 2) {
        if (this.registry_ticket_id === 0 || this.registry_ticket_id === null) {
          this.messageNot('warning', 'warning', 'error')
          return
        }
        this.$store
          .dispatch('registries/updateTicketUser', {
            payload: {
              ticket_user: this.selected,
              registry_ticket_id: this.registry_ticket_id,
            },
          })
          .then(() => {
            this.getLeads()
          })
          .finally(() => {
            this.messageNot('class', 'class', 'success')
          })
      }
      if (this.idAction == 3) {
        this.$store
          .dispatch('registries/deleteTicketUser', {
            payload: {
              ticket_user: this.selected,
            },
          })
          .then(() => {
            this.getLeads()
          })
          .finally(() => {
            this.messageNot('class_delete', 'class_delete', 'success')
          })
      }
    },
    reloadUsers() {
      this.getLeads()
    },
    getLeads() {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = {
        params: {
          page: page,
          rowsPerPage: itemsPerPage,
          sortBy: sortBy[0],
          sortDesc: sortDesc[0],
          filterBy: this.filter_selected,
          search: this.search,
          filter_type: this.filter_type,
          search_enum: this.search_enum,
        },
      }
      const service = this.isMod
        ? EventRegistryResource.Attendees(this.registry.id).index(params)
        : ParticipantsService.make().index(this.registry.id, params)

      return service
        .then((response) => {
          const { total, data } = response.data.data
          this.rows = data
          this.totalRows = total
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
          this.selected = []
        })
    },
  },
}
</script>

<style lang="scss">
.bz-flex-pst {
  display: flex;
  flex-direction: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}
</style>
